// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/Maps.png";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";
import { useLocation } from "react-router-dom";

export interface PageAboutProps {
    className?: string;
}

const FOUNDER_DEMO: People[] = [
    {
        id: "1",
        name: `Niamh O'Shea`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "4",
        name: `Danien Jame`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "3",
        name: `Orla Dwyer`,
        job: "Co-founder, Chairman",
        avatar:
            "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "2",
        name: `Dara Frazier`,
        job: "Co-Founder, Chief Strategy Officer",
        avatar:
            "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
];


const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
    const location = useLocation()
    const path = location.pathname
    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Returns || Oz Cleaning Supplies</title>

                <meta name="description" content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!" />

                <meta name="keywords" content="Cleaning supplies Dandenong" />
                <link rel="canonical" href={path+"/"} />

                <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            {/* <BgGlassmorphism /> */}

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-14">
                {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}

                <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10 ">

                    <div className="">

                        <h2 className="text-lg font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
                            RETURNS
                        </h2>
                        <div className="text-left">
                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-6">
                                Refunds
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Items that are purchased via this site may be refunded within 48 hours of delivery for any unopened product(s) only. All claims after this point will be rejected unless required by law. Refunds will not be given if you supplied an incorrect delivery address and your product(s) did not arrive.
                            </span>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                To obtain a refund, you need to have an “RMA” number which will be issued to you from Oz Cleaning Supplies once you have correctly filled out the “Product Returns” form. The goods may be returned to the sender at the expense of the purchaser.
                            </span>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                To obtain a refund, you will need to get in contact with our customer service team, who will advise you of what actions need to be taken. The goods may be returned to the sender at the expense of the purchaser.
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                Faulty or Damaged Goods Under Warranties
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                In addition to specific guarantees from manufacturers, Oz Cleaning Suppplies and its suppliers provide a guarantee of acceptable quality on every product by law. You’re entitled to a replacement or refund for a major failure. You may also be compensated for other reasonably foreseeable loss or damage. You are also entitled to have the goods replaced or repaired if the goods fail to be of an acceptable quality, but the failure is not the equivalent of what is regarded as a major failure.
                            </span>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                We strongly recommend that you retain a copy of the purchase confirmation/invoice sent to your email, as we may require you to present proof that you purchased the product from us. In the case of any issues, our customer service team will be able to advise whether a refund, exchange, repair or replacement is available.
                            </span>




                            {/* <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8 mt-6">
                {FOUNDER_DEMO.map((item) => (
                  <div key={item.id} className="max-w-sm">
                    <NcImage
                      containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                      className="absolute inset-0 object-cover"
                      src={item.avatar}
                    />
                    <h3 className="text-base font-semibold text-neutral-900 mt-4 md:text-lg dark:text-neutral-200">
                      {item.name}
                    </h3>
                    <span className="block text-xs text-neutral-500 sm:text-sm dark:text-neutral-400">
                      {item.job}
                    </span>
                  </div>
                ))}
              </div> */}

                        </div>
                    </div>

                </div>





                {/* <SectionFounder /> */}
                {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

                {/* <SectionPromo3 /> */}
            </div>
        </div>
    );
};

export default PageAbout;
