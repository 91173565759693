import rightImg from "images/hero-right1.png";
import rightImg1 from "images/service/1.jpg";
import rightImg2 from "images/service/2.jpg";
import rightImg3 from "images/service/3.jpg";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import { useLocation } from "react-router-dom";


export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const location = useLocation()
  const path = location.pathname
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Repairs and servicing || Oz Cleaning Supplies</title>
        <meta name="description" content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!" />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path+"/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      {/* <BgGlassmorphism /> */}

      <div className="container py-16 lg:py-28 space-y-8 lg:space-y-14 ">
        {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}


        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-4 gap-10 ">

          <div className="col-span-3 ...">

            <h2 className="text-lg font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
              CLEANING EQUIPMENT SERVICE AND REPAIRS
            </h2>
            <div className="text-justify">
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                You can count on Oz Cleaning Supplies to keep your cleaning equipment running.
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We support customers all around Melbourne in the maintenance, repair and servicing of your cleaning equipment including vacuums, floor polishers, sweepers and floor scrubbers.
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                With thousands of customers across Melbourne, we provide premium quality service to sectors including hospitals, institutions, government buildings and much more. Our customers turn to us for tailored and flexible preventative maintenance service programs for their cleaning equipment, to ensure that their machinery is running at optimum levels.
              </span>
            </div>
          </div>
          <div className="flex-grow w-full">
            <img className="w-full ml-9" src={rightImg1} alt="" />
          </div>
        </div>

        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-4 gap-10 ">

          <div className="col-span-3 ...">

            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
              Professional Cleaning Equipment Repairs
            </h2>
            <div className="text-justify">
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                Offering easy access to local expertise, Oz Cleaning Supplies has a fully-equipped service van for on-call support. Our service technicians have extensive experience with major machinery brands including Tennant, Nilfisk, Pacvac, and Polivac. To maximise your uptime, we only use genuine spare parts to ensure that you’re always enjoying continuous operation of your key cleaning equipment.
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Our Melbourne warehouse has thousands of spare parts, ensuring that we can offer quick parts deployment. We are confident that you’ll love our commitment in helping your cleaning equipment get up and running!
              </span>
            </div>
          </div>
          <div className="flex-grow">
            <img className="w-full ml-9" src={rightImg3} alt="" />
          </div>
        </div>

        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-4 gap-10 ">

          <div className="col-span-3 ...">

            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
              Easy Access To Rental Machines
            </h2>
            <div className="text-justify">
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                If your machinery has a breakdown, you can count on us for a rental unit whilst yours is being repaired.
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Oz Cleaning Supplies assists thousands of customers across South-East Melbourne with a huge selection of commercial cleaning products and equipment.
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                To find out more about your service options, call us today on (03) 9794 0654.
              </span>
            </div>
          </div>
          <div className="flex-grow">
            <img className="w-full ml-9" src={rightImg2} alt="" />
          </div>
        </div>





        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}

        {/* <SectionStatistic /> */}

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
