import {
    IMAGE_SERVER_URL,
    IMAGE_SERVER_URL_DEV,
    IMAGE_SERVER_URL_DEV2,
} from "../../Constant/System";
import _ from "lodash";
var moment = require("moment");

function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {

        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
}
function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {
            [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
    }, {});
}

export const EMAIL_TAMPLETE2 = ({
    customerName,
    address,
    invoiceNo,
    date,
    tax,
    MESSAGE,
    address2,
    address3,
    address4,
    iUsers = [],
    currency,
    amount,
    delType,
    mobileNumber,
}) => {

    return `
      <html lang='en'>
    
      <head>
          <meta charset='utf-8' />
          <title>INVOICE - Healthy Kitchen</title>
          <meta name='viewport' content='initial-scale=1.0; maximum-scale=1.0; width=device-width;'>
     
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link
              href="https://fonts.googleapis.com/css2?family=Asap+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
              rel="stylesheet">
          <style>
              @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);
      
          
              .txt-primary {
                  color: #164e63
              }
      
              .background-primary {
                  background-color: #164e63
              }
      
              .background-secondary {
                  background-color: #E5DDD8
              }
      
              .background-tertiary {
                  background-color: #326764
              }
      
              .txt-secondary {
                  color: #326764
              }
      
              .invoice-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
              }
      
              .invoice-details-container {
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-end;
                  flex-direction: column;
              }
      
              .txt-invoice {
                  font-family: sans-serif;
                  text-transform: uppercase;
                  text-align: right;
                  font-weight: bold;
                  letter-spacing: 2;
              }
      
              .txt-1 {
                  font-size: 7pt;
                  font-weight: 500;
                  letter-spacing: 0.8px;
                  font-family: 'Roboto';
              }
      
              .txt-2 {
                  font-size: 15pt;
                  font-weight: 600;
                  font-family: sans-serif;
              }
      
              .txt-3 {
                  font-size: 7pt;
                  font-weight: 400;
                  font-family: sans-serif;
              }
      
              .tb-row-1 {
      
                  padding: 5px 0px;
                  font-size: 7pt;
                  font-weight: 400;
                  /* display: flex; */
                  /* align-items: center; */
                  font-family: 'Roboto';
                  /* letter-spacing: 0.8; */
      
              }
      
              .tb-row-2 {
      
                  padding: 6px 0px;
                  font-size: 7pt;
                  font-weight: 400;
                  /* display: flex;
                  align-items: center; */
                  font-family: 'Roboto';
                  letter-spacing: 0.8;
      
              }
      
              .txt-4 {
                  padding: 6px 5px;
                  font-size: 14pt;
                  font-weight: 400;
                  display: flex;
                  align-items: center;
                  font-family: 'Roboto';
                  letter-spacing: 1;
              }
      
              .contact-container {
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
      
              }
      
              .txt-5 {
                  font-size: 11pt;
                  font-weight: 600;
      
                  font-family: 'Roboto';
                  letter-spacing: 2px;
              }
      
              .txt-6 {
                  font-family: 'Roboto';
                  letter-spacing: 0.8px;
                  font-size: 10pt;
                  margin-bottom: 3px;
              }
      
              .txt-7 {
                  font-family: 'Roboto';
                  font-weight: 400;
                  letter-spacing: 0.8px;
                  font-size: 9pt;
              }
      
              .custom-hr {
                  width: 30%;
                  height: 1.5px;
                  margin: 6px 0px 10px;
                  opacity: 1;
              }
      
              th {
                  /* height: 32px;
                  padding: 10px; */
                  height: 25px;
                  padding: 5px;
                  text-align: left;
              }
      
              td {
                  height: 20px;
                  padding: 5px 10px;
                  text-align: left;
      
              }
      
              body {
                  background-color: #fff;
                  padding: 0;
                  margin: 0;
              }
          </style>
      </head>
      
      <body>
      <div class="container" style="background:#fff;">
      <div style="padding: 5%;min-height:628px;">
          <table style="width:100%">
              <tr>
                  <td>
                      <img src="${IMAGE_SERVER_URL_DEV}/uploads/logo/1670255682167.jpeg"
                          style="width:45%;min-width:45%;object-fit:contain" alt="logo" />
                  </td>
                  <td>
                      <h3 class="txt-primary txt-invoice" style="font-size:18pt;margin-bottom: 0px;">
                          INVOICE
                      </h3>
                  </td>
              </tr>
          </table>
          <table style="width:100%">
              <tr>
                  <td>
                      <div style="margin-bottom:2px;" class="txt-secondary txt-1">
                          INVOICE TO:
                      </div>
                      <div style="margin-bottom:2px;white-space: nowrap;color:#222" class="txt-secondary txt-3">
                      ${customerName}
                      </div>
                      <div class="txt-secondary txt-3" style="max-width:85%;color:#222">
                      ${address}
                      <br/>
                      ${address2}
                      <br/>
                      ${address3}
                      <br/>
                      ${address4}
                      <br/>
                      ${mobileNumber}
                      </div>
                  </td>
                  <td style="text-align: right;display:block;">
                      <div style="margin-bottom:2px;white-space: nowrap;" class="txt-secondary txt-1">
                          INVOICE DETAILS
                      </div>
                      <div style="margin-bottom:2px;color:#222" class="txt-secondary txt-3">
                      No. ${invoiceNo}
                      </div>
                      <div style="color:#222" class="txt-secondary txt-3">
                      ${moment(new Date(date)).format("DD/MM/YYYY")}
                      </div>
                  </td>
              </tr>
          </table>
  
          <div style="padding: 15px 0px 5px; ">
              <table style="width:100%;border-spacing: 0;">
  
                  <tr class=" background-primary" style="color: white;
                    
                      font-size: 7pt;
                      font-weight: 500;">
                      <th style="width:50%;">Product</th>
                      <th style="text-align:center;">QTY</th>
                      <th>PRICE</th>
                      <th>SUBTOTAL</th>
                  </tr>
                  ${iUsers?.map((item, index) => {

        let unitPrice = 0;
        let discount = item.discount;
        let cartQty = item.qty >= 1 ? item.qty : 1;

        //   const newArr = JSON.parse(item.priceDetails)?.map(single => {

        //     if (cartQty === single.qty)
        //       unitPrice = single.unitPrice
        //   })

        unitPrice = item.unitprice;


        // const discountedPrice = cartItem.discountedprice;
        // const finalProductPrice = cartItem.unitprice?.toFixed(2);
        // const finalDiscountedPrice = cartItem.discountedprice?.toFixed(2);
        const finalProductPrice = parseFloat(unitPrice)
        const discountedPrice = parseFloat(unitPrice * discount / 100)
        const finalDiscountedPrice = parseFloat(unitPrice * (100 - discount) / 100 * cartQty).toFixed(2)
        return `
                    <tr class="txt-secondary tb-row-1 ">
                    
                    <td>${item?.productName}<br />
                    SKU: ${item?.sku}<br/>               
                    Size: ${item?.selectedProductSize}<br/>
                    Color: ${item?.selectedProductColor}
                    </td>
                    <td style="text-align:center;">${item?.qty}</td>
                    <td>${currency} ${parseFloat(item?.unitprice).toFixed(2)}</td>
                    <td>${currency} ${finalDiscountedPrice}</td>
                </tr>
                    `;
    })}
                
                  <tr>
                      <td colspan="4" style="height:0;padding-top:0;padding:3px;border-top:2px solid #326764 ;">
                      </td>
                  </tr>
                  <tr class="txt-secondary tb-row-1">
                      <td style="height:18px;padding:0px 10px" style="text-align:center;" colspan="2"></td>
  
                      <td style="height:18px;padding:2px 10px 0px 10px;text-align: right;">Subtotal</td>
                      <td style="height:18px;padding:5px 10px;text-align: right;">${currency} ${parseFloat(amount).toFixed(2)}</td>
                  </tr>

                  ${delType === "delivery" ?

            ` <tr class="txt-secondary tb-row-1">
            <td style="height:18px;padding:0px 10px" style="text-align:center;" colspan="2"></td>

            <td style="height:18px;padding:2px 10px 0px 10px;text-align: right;">Shipping</td>
            <td style="height:18px;padding:5px 10px;text-align: right;">${currency} ${parseFloat(16.95).toFixed(2)}</td>
        </tr>
`
            :

            ""

        }

                 
             

                  <tr class="txt-secondary tb-row-1 ">
                      <td style="height:18px;padding:0px 10px" style="text-align:center;" colspan="2"></td>
  
                      <td style="height:18px;padding:2px 10px 0px 10px;text-align: right;">Total</td>
                      <td style="height:18px;padding:5px 10px;text-align: right;">${currency} ${parseFloat((amount) + (delType === "delivery" ? 16.95 : 0)).toFixed(2)}</td>
                  </tr>
              </table>
          </div>
      </div>
      
              <div style="padding: 30px 25px 10px;font-family: sans-serif;          
              text-align: center;
              font-size: 13pt;">
                  
                 
       
        <div class="contact-container " style="display: block; text-align:center ;margin-bottom: 40px;margin-top:20px">
        <h3 style="margin-bottom: 5px;display: block; " class="txt-primary txt-5">
            CONTACT SUPPORT
        </h3>
        <hr class="background-tertiary custom-hr" style="display: inline-block;" />
        <h4 style="margin-top: 0px;" class=" txt-secondary txt-6">
        (03) 9794 0654
        </h4>
        <h5 style="margin:5px" class="txt-secondary txt-7">
        info@ozcleaningsupplies.com.au
        </h5>
    </div>
        </div>
        </div>
          </div>
          </div>
            </div>
        </body>
        
        </html>`;
};
