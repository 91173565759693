import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsListNew";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { _getImgRd, _getPersonNameRd, _getTitleRd } from "contains/fakeData";
import Tag from "shared/Tag/Tag";
import image1 from "images/blogs/5.jpg"
import image11 from "images/blogs/1.jpg";
import image12 from "images/blogs/2.jpg";
import image13 from "images/blogs/3.jpg";

const blogs: any = [
  {
    title: "How To Remove A White Heat Stain From Wood Furniture",
    desc: "Not ideal. Someone forgot to put a coaster under their hot cup of coffee and now there is a heat ring or watermark from their spillage and drinks. There...",
    date: "November 12, 2018",
    href: "/blog-single",
    image: image11
  },
  {
    title: "How To Clean Drains Without Chemicals",
    desc: "If you walk into commercial building or household after a comprehensive cleaning session and you’ll find a pristine looking kitchen, bathroom or laundry sink.",
    date: "October 25, 2018",
    href: "/blog-single-2",
    image: image12
  },
  {
    title: "Inside The Mind Of Your Weird Office Cleaning Client",
    desc: "No matter how hard I try, it’s an overwhelming challenge to keep my office, let alone all of my colleague’s desks clean.  I know, I know, we are our...",
    date: "October 14, 2018",
    href: "/blog-single-3",
    image: image13
  },

]

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <Helmet>
          <title>Blog || Oz Cleaning Supplies</title>
          <meta name="description" content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!" />

          <meta name="keywords" content="Cleaning supplies Dandenong" />
          <link rel="canonical" href={"/blog-single-2/"} />

          <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/blog-single-2"
}
    `}</script>
        </Helmet>
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name="Cleaning Tips" />
          <h1
            className=" text-neutral-900 font-semibold text-2xl md:text-3xl md:!leading-[120%] lg:text-3xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            HOW TO CLEAN DRAINS WITHOUT CHEMICALS
          </h1>
          <span className="block text-sm text-neutral-500 md:text-base dark:text-neutral-400 pb-1">
            {/* We’re an online magazine dedicated to covering the best in
            international product design. We started as a little blog back in
            2002 covering student work and over time */}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-center sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-xs leading-none flex-shrink-0">
              {/* <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="##">
                    Fones Mimi
                  </a>
                </div>
                <div className="text-[10px] mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    May 20, 2021
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>
                </div>
              </div> */}
            </div>
            <div className="mt-3 sm:mt-1.5 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert text-justify"
      >
        <p>
          If you walk into commercial building or household after a comprehensive cleaning session and you’ll find a pristine looking kitchen, bathroom or laundry sink.
        </p>
        <p>
          But lurking underneath the surface are tons is a build up that nightmares are made of. Lurking down your drain hole is a build-up of soap scum, food scraps, hair and lots of nasties breeding like bacteria.
        </p>
        <p>
          Hoping that it will go away may bite down the road. You’ll start to notice a horrendous nose-curdling odours, along with the potential for serious blockages that may ultimately require expensive plumbing repair work. You’ll notice potential blockages when water is not flowing through a ‘slow drain’. It’s time to act fast!
        </p>
        <p>
          It’s time to stop throwing money down the sink (literally!) and get on top of these drains; exactly where families wash their hands (and in business environments germs can spread, impacting attendance and productivity).
        </p>
        <p>
          Whilst chemicals do a ripper job of unclogging drains, it is a personal choice of many to seek a more natural alternative to the problem.
        </p>
        <p>
          A Consistent Plan Of Action <br />
          Failing to plan is planning to fail! It’s best to ensure that once every month you clean your drains. You’ll thank yourself later; having blockage free, flowing drains and fresh smelling drains,
        </p>
        <p>
          And this work will be done in only 10 minutes! <br />
          Let’s Get Started <br />
          It’s highly likely that every cleaner will have these two simple ingredients at their disposal to get the process started.
        </p>
        <ol>
          <li>Almost every pantry has bicarbonate soda sitting in it. If not, it’s an easy-to-find item in the baking section of every supermarket. Simply tip a cup of bicarb into the drain, ensuring that you aim to get some on the drain net.</li>
          <li>
            Heat a cup of vinegar into your microwave for two minutes. The upside is that the steam from the vinegar will also clean your microwave – simply give it a quick wipe down with a paper towel!
          </li>
          <li>
            Pour the heated vinegar down your drain. You’ll see an immediate reaction with your bicarb soda, with it bubbling away.
          </li>
          <li>
            Let the products do their magic, and come back in 5 minutes.
          </li>
          <li>
            Finish off the job by flushing warm water down your sinks for 30 seconds.
          </li>

        </ol>
        <p>
          It’s really that easy! Following these 5 simple steps works effectively on steel or ceramic sinks/drains.
        </p>

        {/* <p>
          It is a long established fact that a <strong>reader</strong> will be
          distracted by the readable content of a page when looking at its{" "}
          <strong>layout</strong>. The point of using Lorem Ipsum is that it has
          a more-or-less normal{" "}
          <a href="/#" target="_blank" rel="noopener noreferrer">
            distribution of letters.
          </a>{" "}
        </p> */}
        {/* <ol>
          <li>We want everything to look good out of the box.</li>
          <li>
            Really just the first reason, that's the whole point of the plugin.
          </li>
          <li>
            Here's a third pretend reason though a list with three items looks
            more realistic than a list with two items.
          </li>
        </ol>
        <h3>Typography should be easy</h3>
        <p>
          So that's a header for you — with any luck if we've done our job
          correctly that will look pretty reasonable.
        </p>
        <p>Something a wise person once told me about typography is:</p>
        <blockquote>
          <p>
            Typography is pretty important if you don't want your stuff to look
            like trash. Make it good then it won't be bad.
          </p>
        </blockquote>
        <p>
          It's probably important that images look okay here by default as well:
        </p>
        <figure>
          <img
            src="https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1045&amp;q=80"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure vel
            officiis ipsum placeat itaque neque dolorem modi perspiciatis dolor
            distinctio veritatis sapiente
          </figcaption>
        </figure>
        <p>
          Now I'm going to show you an example of an unordered list to make sure
          that looks good, too:
        </p>
        <ul>
          <li>So here is the first item in this list.</li>
          <li>In this example we're keeping the items short.</li>
          <li>Later, we'll use longer, more complex list items.</li>
        </ul>
        <p>And that's the end of this section.</p>
        <h2>Code should look okay by default.</h2>
        <p>
          I think most people are going to use{" "}
          <a href="https://highlightjs.org/">highlight.js</a> or{" "}
          <a href="https://prismjs.com/">Prism</a> or something if they want to
          style their code blocks but it wouldn't hurt to make them look{" "}
          <em>okay</em> out of the box, even with no syntax highlighting.
        </p>
        <p>
          What I've written here is probably long enough, but adding this final
          sentence can't hurt.
        </p>

        <p>Hopefully that looks good enough to you.</p>
        <h3>We still need to think about stacked headings though.</h3>
        <h4>
          Let's make sure we don't screw that up with <code>h4</code> elements,
          either.
        </h4>
        <p>
          Phew, with any luck we have styled the headings above this text and
          they look pretty good.
        </p>
        <p>
          Let's add a closing paragraph here so things end with a decently sized
          block of text. I can't explain why I want things to end that way but I
          have to assume it's because I think things will look weird or
          unbalanced if there is a heading too close to the end of the document.
        </p>
        <p>
          What I've written here is probably long enough, but adding this final
          sentence can't hurt.
        </p> */}
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap space-x-2">
        <Tag />
        <Tag />
        <Tag />
        <Tag />
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-[10px] text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-base font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="##">Fones Mimi</a>
            </h2>
            <span className="text-xs text-neutral-500 sm:text-sm dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="##">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-lg font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (_: any, index: number) => {
    return (
      <div
        key={index}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={_.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          // src={_getImgRd()}
          src={_.image}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-[10px] text-neutral-300 space-y-2.5 p-4">
          <Badge name="Cleaning Tips" />
          <h2 className="block text-base font-semibold text-white ">
            <span className="line-clamp-2">
              {/* {_getTitleRd()} */}
              {_.title}
            </span>
          </h2>

          <div className="flex">
            {/* <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {_getPersonNameRd()}
            </span>
            <span className="mx-1.5 font-medium">·</span> */}
            <span className="font-normal truncate">{_.date}</span>
          </div>
        </div>
        <Link to={_.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Single Blog || Oz Cleaning Supplies </title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        // src="https://images.unsplash.com/photo-1605487903301-a1dff2e6bbbe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1957&q=80"
        src={image1}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/* {renderAuthor()} */}
        {/* {renderCommentForm()} */}
        {/* {renderCommentLists()} */}
      </div>
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-2xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {/*  */}
            {/* {[1, 1, 1, 1].filter((_, i) => i < 4).map(renderPostRelated)} */}
            {blogs.map(renderPostRelated)}
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSingle;
