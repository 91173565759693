import ProductCard from "components/ProductCardNew";
import WishContext from "Context/WishContext";
import { PRODUCTS } from "data/data";
import React, { useContext, useState } from "react";
import { getWishlistList } from "Services/API/Get";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const AccountSavelists = () => {
  const location = useLocation()
  const path = location.pathname
  const [array, setArray] = useState([]);

  const [lopen, setlopen] = useState(false);


  const [limit, setlimit] = useState(6);

  const { wish, setWish } = useContext(WishContext);



  React.useEffect(() => {
    getWish();

  }, [wish, limit])

  const getWish = () => {
    setlopen(true);
    getWishlistList({ limit: limit })
      .then((res) => {
        setlopen(false);
        console.log("get Wish page>>>>>", res)

        setArray(res.data);
        // setCart(res.data.length);

        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((error) => {
        console.error(error);
        setlopen(false);
        setArray([]);
        // setCart(0);
      });

  }





  const renderSection1 = () => {

    return (
      <div className="space-y-10 sm:space-y-12">
        <div>
          <h2 className="text-xl sm:text-2xl font-semibold">
            List of saved products
          </h2>
        </div>

        {
          array.length > 0 ?

            <>
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 ">
                {array.map((stay: any) => (
                  <ProductCard key={stay.id} data2={stay} />
                ))}
              </div>
              <div className="flex !mt-20 justify-center items-center">
                <ButtonSecondary
                  onClick={() => setlimit(limit + 3)}
                  loading={lopen}
                >Show me more</ButtonSecondary>
              </div>

            </>

            :
            <>
              <div className="flex !mt-20 justify-center items-center">
                <p className="text-lg font-semibold">
                  {/* #WU3746HGG12 */}
                  No items found in wishlist
                </p>

              </div>
              <div className="flex  justify-center items-center">

                <a href="/shop" >
                  <ButtonPrimary>Shop Now</ButtonPrimary>
                </a>
              </div>

            </>

        }


      </div>
    );
  };

  return (
    <div>

      <Helmet>
        <title>Save List - Oz Cleaning Supplies</title>
        <meta name="description" content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!" />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path+"/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
