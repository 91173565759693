import React, { FC, useState, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { addToast } from "shared/Toast";
import { LoginQuery } from "Services/API/Post";
import jwt_decode from "jwt-decode";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  const Login = async (e?: any) => {

    e.preventDefault();

    if (username === "") {
      seterrerrUsername("This Field Required");
    }

    if (username !== "") {
      seterrerrUsername("");
    }

    if (lpass === "") {
      seterrerrLpass("This Field Required");
    }

    if (lpass !== "") {
      seterrerrLpass("");
    }
    // eslint-disable-next-line no-console

    let obj = {
      email: username,
      password: lpass,
    };

    if (username !== "" && lpass !== "") {
      // setlOpen(true);
      LoginQuery(obj)
        .then((res) => {
          // setUser("logged");
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Login Successful");

          localStorage.clear();

          let decoded: any = null;

          decoded = jwt_decode(res?.data);

          if (res?.status) {
            localStorage.setItem(
              `${window.location.hostname}.login`,
              JSON.stringify(res)
            );

            localStorage.setItem("exprTime", decoded?.exp);
            // localStorage.setItem("remember", rememberMe);
          }

          // history.push("/");
          // window.location.reload();

          setTimeout(() => {
            history.push("/");
            window.location.reload();
          }, 0);
        })
        .catch((error) => {
          // setlOpen(false);
          console.log(error);

          if (error.response.data.statusCode === 401) {
            addToast(error.response.data.message, "warning");
            // seterrerrLpass(error.response.data.message);
          } else if (error.response.data.statusCode === 500) {
            addToast(error.response.data.errorMessage, "warning");
            // seterrerrUsername(error.response.data.errorMessage);
          } else {
            addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor("red");
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          }
        });
    }



  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Booking React Template</title>
        <meta name="description" content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!" />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={"/login/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/login"
}
    `}</script>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-2xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-xs font-medium text-neutral-700 dark:text-neutral-300 sm:text-xs">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-xs bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={Login}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={(e) => {
                  setusername(e.target.value);
                  seterrerrUsername("");
                }}
                value={username}
              />
              {errUsername && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errUsername}
                </div>
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/reset-password" className="text-xs text-green-600">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" className="mt-1"
                placeholder="Password"
                onChange={(e) => {
                  setlpass(e.target.value);
                  seterrerrLpass("");
                }}
                value={lpass}
              />
              {errLpass && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errLpass}
                </div>
              )}
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link className="text-green-600" to="/signup">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
