// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/Maps.png";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";
import { useLocation } from "react-router-dom";

export interface PageAboutProps {
    className?: string;
}

const FOUNDER_DEMO: People[] = [
    {
        id: "1",
        name: `Niamh O'Shea`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "4",
        name: `Danien Jame`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "3",
        name: `Orla Dwyer`,
        job: "Co-founder, Chairman",
        avatar:
            "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "2",
        name: `Dara Frazier`,
        job: "Co-Founder, Chief Strategy Officer",
        avatar:
            "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
];


const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
    const location = useLocation()
    const path = location.pathname
    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Terms and Conditions || Oz Cleaning Supplies</title>

                <meta name="description" content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!" />

                <meta name="keywords" content="Cleaning supplies Dandenong" />
                <link rel="canonical" href={path+"/"} />

                <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            {/* <BgGlassmorphism /> */}

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-14">
                {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}

                <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10 ">

                    <div className="">

                        <h2 className="text-lg font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
                            TERMS AND CONDITIONS
                        </h2>
                        <div className="text-left">
                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-6">
                                User Agreement
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                These terms and conditions apply to all purchases made from Oz Cleaning Supplies.
                            </span>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                The term Oz Cleaning Supplies or “us” or “we” refers to the owner of the website. Our business registration number is ABN: 56 080 785 815. The term “you” refers to the user or viewer of the website.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                Your Order
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                All orders placed through ozcleaningsupplies.com.au are subject to confirmation and acceptance by Oz Cleaning Supplies. By placing an order online, you are making an offer to us to purchase the products you have selected for the price (including delivery and other charges) based on these Terms and Conditions. Oz Cleaning Supplies reserves the right to accept or reject any offer for any reason, including without limitation, an error in the price of the product description posted on this website, the unavailability of a product, or error in your order. At placement of the order, we will issue you with an order number for reference. We will confirm acceptance of your order when we send you an email confirming shipment of the products you have ordered. If we can not process or accept your order after payment is made, we will contact you by phone or email. Each order is processed the following business day or once the payment has been cleared.
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                Delivery Of Your Products
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                The delivery time can take up to 10 working days from the date your payment is received. Oz Cleaning Supplies will make every effort to deliver your products according to the estimated delivery time provided by us at checkout. The estimated delivery times are in business days (Monday through to Friday) excluding Public holidays. Although every effort is made to ship your order according to the estimated delivery times provided, this may change due to circumstances beyond our control. If your product(s) have not arrived after the estimated delivery time, please feel free to contact us. Oz Cleaning Supplies or its agent will deliver the product(s) you have ordered to the delivery address that you specify in your order. Any persons at the delivery address who receives the product will be presumed by Oz Cleaning Supplies to be authorised to receive the products. If there is no one at that delivery address to receive or pay for the order, Oz Cleaning Supplies may charge you additional delivery fees. Once the products have been delivered to you, you will own them and it is your responsibility if they are lost or damaged.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                Payment Methods and Processing
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                We offer payments using Visa and Mastercard through our 256-bit SSL secure online payment system powered by Eway. We also offer payments through PayPal which include Visa, Mastercard, American Express and direct bank payments.
                            </span>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                For each online order, you must pay the applicable price for the relevant goods confirmed by Oz Cleaning Supplies and the delivery and handling fee specified on the web site at that time. Your order and credit card details are safe and secure. All personal and credit card information provided to Oz Cleaning Supplies is encoded using 256-bit SSL (Secure Sockets Layer) technology, the industry standard encryption protocol that protects data as it travels over the Internet. We are unable to accept Cost on Delivery charges at this time. Payment must be cleared before the products are dispatched.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                Refunds
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Items that are purchased via this site may be refunded within 48 hours of delivery for any unopened product(s) only. All claims after this point will be rejected unless required by law. Refunds will not be given if you supplied an incorrect delivery address and your product(s) did not arrive. To obtain a refund, you need to have an “RMA” number which will be issued to you from Oz Cleaning Supplies once you have correctly filled out the “Product Returns” form. The goods may be returned to the sender at the expense of the purchaser.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                GST
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                All fees and charges identified in these terms and conditions and all prices for the products purchased on the Oz Cleaning Supplies website include GST, where applicable.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                Products
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                All of our products are sent to you in good condition and is packaged correctly. We cannot guarantee the condition of the product on arrival.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                Your Account
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                You agree to take responsibility for the safekeeping of your user name and password. You are liable if your user name or password is used by an unauthorised person. You must contact Oz Cleaning Supplies as soon as possible when you feel your account has been compromised and we will respond accordingly.  Oz Cleaning Supplies may suspend or cancel your account at any time without prior notice.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                Images and Graphics
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Oz Cleaning Supplies aims to include up to date images and/or graphics of all of the products on our website. However, our picture of the goods may differ from the actual goods. We recommend you read the labels on the products carefully before using the goods.
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                                Privacy
                            </h2>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Oz Cleaning Supplies will comply with Oz Cleaning Supplies Privacy Statement. Oz Cleaning Supplies will not reveal your personal information  to external or third party organisations except for the purposes of fulfilling your order. Your use of the website and any dispute arising out of such use of the website is subject to the laws of the Australian Government as outlined in the Privacy Act 1988. Information Privacy Principles under the Privacy Act 1988 and further legislation is available at <a href="https://www.privacy.gov.au" target="_blank" className="font-semibold prose prose-sm  sm:prose lg:prose-lg dark:prose-invert"> www.privacy.gov.au.</a>
                            </span>




                            {/* <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8 mt-6">
                {FOUNDER_DEMO.map((item) => (
                  <div key={item.id} className="max-w-sm">
                    <NcImage
                      containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                      className="absolute inset-0 object-cover"
                      src={item.avatar}
                    />
                    <h3 className="text-base font-semibold text-neutral-900 mt-4 md:text-lg dark:text-neutral-200">
                      {item.name}
                    </h3>
                    <span className="block text-xs text-neutral-500 sm:text-sm dark:text-neutral-400">
                      {item.job}
                    </span>
                  </div>
                ))}
              </div> */}

                        </div>
                    </div>

                </div>





                {/* <SectionFounder /> */}
                {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

                {/* <SectionPromo3 /> */}
            </div>
        </div>
    );
};

export default PageAbout;
