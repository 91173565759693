// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/1.jpg";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";
import SectionPromo1 from "components/SectionPromoHeader";

export interface PageAboutProps {
  className?: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Niamh O'Shea`,
    job: "Co-founder and Chief Executive",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "4",
    name: `Danien Jame`,
    job: "Co-founder and Chief Executive",
    avatar:
      "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "3",
    name: `Orla Dwyer`,
    job: "Co-founder, Chairman",
    avatar:
      "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "2",
    name: `Dara Frazier`,
    job: "Co-Founder, Chief Strategy Officer",
    avatar:
      "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
];


const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Oz Cleaning Supplies</title>
        <meta name="description" content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!" />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={"/about/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/about"
}
    `}</script>

      </Helmet>

      <SectionPromo1 />

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-14">
        {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}

        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10 ">

          <div className="">

            <h2 className="text-lg font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
              COMMERCIAL CLEANING PRODUCT AND EQUIPMENT SPECIALISTS
            </h2>
            <div className="text-left">
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                Family owned and operated, Oz Cleaning Supplies has proudly served Melbourne businesses for over 35 years.
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Based in Melbourne’s South East Suburbs, Oz Cleaning Supplies is one of Australia’s leading providers of commercial cleaning supplies and equipment. With thousands of products available, we carry a comprehensive selection of cleaning products.
              </span>

              <div className="flex-grow mt-6">
                <img className="w-full" src={rightImg} alt="" />
              </div>

              {/* <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8 mt-6">
                {FOUNDER_DEMO.map((item) => (
                  <div key={item.id} className="max-w-sm">
                    <NcImage
                      containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                      className="absolute inset-0 object-cover"
                      src={item.avatar}
                    />
                    <h3 className="text-base font-semibold text-neutral-900 mt-4 md:text-lg dark:text-neutral-200">
                      {item.name}
                    </h3>
                    <span className="block text-xs text-neutral-500 sm:text-sm dark:text-neutral-400">
                      {item.job}
                    </span>
                  </div>
                ))}
              </div> */}

            </div>
          </div>

        </div>

        <div className="grid grid-cols-1 lg:grid-cols-1 gap-10">

          <div className="">

            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
              Servicing Customers Across Melbourne
            </h2>
            <div className="text-left">
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                Currently servicing many of Melbourne’s largest organisations, government buildings and institutions, Oz Cleaning Supplies is your one-stop-shop for cleaning products and supplies. Our products range from washroom supplies, to commercial chemicals, through to industrial cleaning equipment.
              </span>

            </div>
          </div>

        </div>

        <div className="grid grid-cols-1 lg:grid-cols-1 gap-10">

          <div className="">

            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
              Cleaning Equipment Solutions
            </h2>
            <div className="text-left">
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                Let us help you get it right the first time. We can come to for a free onsite assessment to ensure that you get exactly the right machine for the job. Supporting professional cleaners with the right advice is only part of the job.
              </span>

              <div
                id="single-entry-content"
                className="prose prose-sm  w-full sm:prose lg:prose-lg dark:prose-invert lg:whitespace-nowrap mt-5 mb-5"
              >
                <ul>
                  <li>Short and long term rentals are available.</li>
                  <li>Large selection of stock available in our warehouse, ready for immediate delivery</li>
                  <li>The security of an experienced and highly trained service division to assist you with all of your repairs.</li>
                  <li>Rapid parts deployment to get you up and running quickly after any breakdown.</li>
                </ul>
              </div>



              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We are the only official Tennant supplier to Melbourne’s south-eastern suburbs. In addition to the latest Tennant commercial and industrial cleaning equipment solutions, we stock major brands such as Polivac, Nilfisk and PacVac. To find out more about how Oz Cleaning Supplies can support your cleaning business call us on (03) 9794 0654.
              </span>

            </div>
          </div>

        </div>

        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
