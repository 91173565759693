import React, { useState, FC } from "react";

import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import MySwitch from "components/MySwitch";
import Input from "shared/Input/Input";
import { Autocomplete } from "@mui/material";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import EmptyIMG from "images/empty.png";
import _, { lowerCase } from "lodash";
import { useHistory } from "react-router-dom";

export interface CategorProps {
  mainId?: any;
  maincategory?: string;
}

export interface SubCategorProps {
  id?: any;
  name?: any;
  description?: any;
}

export interface SectionSliderProductCardProps {
  categories?: CategorProps[],
  setSelectedcat?: any,
  selectedCat?: any,
  allSubs?: SubCategorProps[],
  setselectedSub?: any,
  selectedSub?: any,
  allSizes?: any,
  allColours?: any,
  selectedSize?: any,
  selectedColour?: any,
  setselectedSize?: any,
  setselectedColour?: any,
  setminPrice?: any,
  minPrice?: any,
  setmaxPrice?: any,
  maxPrice?: any,
  setFilterSortValue?: any,
  filterSortValue?: any,
  setCurrentPage?: any,
  setmainDesc?: any,
  setsearch?: any,
  search?: any,
  allProd?: any,
  setsearch2?: any,
  search2?: any,

}

// DEMO DATA
const DATA_categories = [
  {
    name: "Backpacks",
  },
  {
    name: "Travel Bags",
  },
  {
    name: "Laptop Sleeves",
  },
  {
    name: "Organization",
  },
  {
    name: "Accessories",
  },
];

const DATA_colors = [
  { name: "White" },
  { name: "Beige" },
  { name: "Blue" },
  { name: "Black" },
  { name: "Brown" },
  { name: "Green" },
  { name: "Navy" },
];

const DATA_sizes = [
  { name: "XS" },
  { name: "S" },
  { name: "M" },
  { name: "L" },
  { name: "XL" },
  { name: "2XL" },
];

const DATA_sortOrderRadios = [
  // { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  { name: "Newest", id: "latest" },
  { name: "Price Low - Hight", id: "LowToHigh" },
  { name: "Price Hight - Low", id: "HighToLow" },
];

const PRICE_RANGE = [1, 500];
//
const SidebarFilters: FC<SectionSliderProductCardProps> = ({
  categories = [{ mainId: "", maincategory: "" }],
  setSelectedcat,
  selectedCat,
  allSubs = [],
  setselectedSub,
  selectedSub,
  allSizes = [],
  allColours = [],
  selectedSize,
  selectedColour,
  setselectedSize,
  setselectedColour,
  setminPrice,
  minPrice,
  setmaxPrice,
  maxPrice,
  setFilterSortValue,
  filterSortValue,
  setCurrentPage,
  setmainDesc,
  setsearch,
  search,
  allProd = [],
  setsearch2,
  search2,

}) => {

  const history = useHistory();
  //
  const [isOnSale, setIsIsOnSale] = useState(true);
  const [rangePrices, setRangePrices] = useState([minPrice, maxPrice]);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);
  const [colorsState, setColorsState] = useState<string[]>([]);
  const [sizesState, setSizesState] = useState<string[]>([]);
  const [sortOrderStates, setSortOrderStates] = useState<string>("");

  //
  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };

  const handleChangeColors = (checked: boolean, name: string) => {
    checked
      ? setColorsState([...colorsState, name])
      : setColorsState(colorsState.filter((i) => i !== name));
  };

  const handleChangeSizes = (checked: boolean, name: string) => {
    checked
      ? setSizesState([...sizesState, name])
      : setSizesState(sizesState.filter((i) => i !== name));
  };

  const [newSubs2, setnewSubs2] = useState([]);


  const removeLocal = (id: any) => {
    if (id === 4) {
      localStorage.removeItem("sMain");
    }
    // localStorage.removeItem("sMain");
    // localStorage.removeItem("sSub");
  }

  //

  // OK
  const renderTabsCategories = () => {

    return (
      <div className="relative flex flex-col pb-8 space-y-4">
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            {/* First Name */}
          </span>
          <Autocomplete
            className="mt-1.5"
            // className="form-group"
            // className="border-neutral-200"
            disablePortal
            // defaultValue={{ name_en: homeDistrict }}
            // value={{ name_en: homeDistrict }}

            // id="combo-box-demo"
            options={newSubs2}
            getOptionLabel={(option: any) => option?.productName}
            renderOption={(props: any, option: any) =>
              <div className="flex p-0 items-center" {...props}>
                <div className="flex w-10 h-10 w-full mr-2" >
                  <img
                    src={
                      option?.images && JSON.parse(option?.images).length > 0 ?

                        `${IMAGE_SERVER_URL_DEV}${JSON.parse(option?.images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}` :

                        EmptyIMG

                    }
                    className="object-cover w-full h-full"
                  />
                </div>
                <div>
                  {option.productName}
                </div>
              </div>
            }
            onInputChange={(event, value) => {
              console.log(value)

              if (value) {

                let char = lowerCase(value)

                let arr: any = [...allProd]

                // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

                const det = arr.map((item: any, index: any) => {

                  // if (lowerCase(item.productName).startsWith(char)) {
                  if (lowerCase(item.productName).includes(char)) {

                    return { ...item }
                  }
                })


                console.log(">>>>>", _.compact(det))

                arr = _.compact(det)

                // arr = _.map(arr, "locality")

                // console.log("arr>>", arr)

                arr = _.uniq(arr);

                console.log(">>>>>", arr)


                setnewSubs2(arr);

              } else {
                setnewSubs2([])
              }
            }}
            onChange={(event, value) => {
              if (value) {
                history.push(`/product-detail/${value?.productId}`)
                setsearch(value?.productName);
                setselectedColour("");
                setselectedSize("");
                setCurrentPage(1);
                setSelectedcat("");
              } else {
                setsearch("");
                setselectedColour("");
                setselectedSize("");
                setCurrentPage(1);
                setSelectedcat(4);

              }



              // handleChangeHdist(event, value);
              // setCountry(value);
            }}
            // sx={{
            //   // width: "80vw",
            //   background:"transparent",
            //   borderRadius: "20px",
            //   // border: "none",
            //   // borderColor: "transparent",

            //   "& .MuiInputBase-input": {
            //     height: 15,
            //     // border: "none",
            //     fontSize: "14px",
            //     fontFamily: "'Jost', sans-serif",
            //     // borderColor: "transparent",
            //   },
            //   //view
            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            //     // borderColor: "transparent",
            //   },

            //   "&:hover .MuiOutlinedInput-input": {
            //     color: "black",
            //   },
            //   "&:hover .MuiInputLabel-root": {
            //     color: COLORS.GrayBackground,
            //   },
            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
            //   {
            //     // borderColor: "transparent",
            //   },
            //   ".MuiAutocomplete-root fieldset:hover": {
            //     color: "transparent",
            //     // borderColor: "transparent",
            //   },
            // }}

            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <Input {...params.inputProps} className="mt-1.5" placeholder="Search Product Name" />
              </div>
            )}
            ListboxProps={{
              style: {
                maxHeight: "150px",
                fontSize: "14px",
                // border: '1px solid white'
              },
            }}
          />
          {/* <Input
            type="text"
            placeholder="Search Product Name"
            className="mt-1"
            onChange={(e: any) => {
              setsearch(e.target.value);
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              // seterrfirstName(false);
            }}
            value={search}
          /> */}
          {/* {errfirstName && (
            <div className="text-red-600 text-xs text-right mt-2">
              This Field is Required
            </div>
          )} */}
        </label>
        <h3 className="font-semibold mb-2.5">Categories</h3>
        <Checkbox
          name={""}
          label={"All"}

          checked={selectedCat === ""}
          sizeClassName="w-5 h-5"
          labelClassName="text-xs font-normal"
          onChange={(checked) => {
            setSelectedcat(""); setselectedColour(""); setselectedSize(""); setCurrentPage(1); setmainDesc(""); setsearch(""); 
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }}
        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
        />
        {categories.map((item) => (
          <div key={item.mainId} className="">
            <Checkbox
              name={item.mainId}
              label={item.maincategory}
              checked={selectedCat === item.mainId}
              // defaultChecked={categoriesState.includes(item.mainId)}
              sizeClassName="w-5 h-5"
              labelClassName="text-xs font-normal"
              onChange={(checked) => {
                setSelectedcat(item.mainId); setselectedColour(""); setselectedSize(""); setCurrentPage(1); removeLocal(item.mainId); setmainDesc(item); setsearch("");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              }}
            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
            />

          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsSubCategories = () => {

    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Sub Categories</h3>
        {allSubs.map((item) => (
          <div key={item.id} className="">
            <Checkbox
              name={item.id}
              label={item.name}
              checked={selectedSub === item.id}
              // defaultChecked={categoriesState.includes(item.mainId)}
              sizeClassName="w-5 h-5"
              labelClassName="text-xs font-normal"
              onChange={(checked) => {
                setselectedSub(item.id);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              }}
            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
            />

          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsColor = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Colors</h3>
        <Checkbox
          sizeClassName="w-5 h-5"
          labelClassName="text-xs font-normal"
          name={""}
          label={"All"}
          checked={selectedColour === ""}
          onChange={(checked) => {
            setselectedColour("");
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }}
        // defaultChecked={colorsState.includes(item.name)}
        // onChange={(checked) => handleChangeColors(checked, item.name)}
        />
        <Checkbox
          sizeClassName="w-5 h-5"
          labelClassName="text-xs font-normal"
          name={"none"}
          label={"None"}
          checked={selectedColour === "none"}
          onChange={(checked) => { setselectedColour("none") }}
        // defaultChecked={colorsState.includes(item.name)}
        // onChange={(checked) => handleChangeColors(checked, item.name)}
        />
        {allColours.map((item: any) => (
          <div key={item} className="">
            <Checkbox
              sizeClassName="w-5 h-5"
              labelClassName="text-xs font-normal capitalize"
              name={item}
              label={item}
              checked={selectedColour === item}
              onChange={(checked) => {
                setselectedColour(item);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              }}

            // defaultChecked={colorsState.includes(item.name)}
            // onChange={(checked) => handleChangeColors(checked, item.name)}
            />
          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsSize = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Sizes</h3>
        <Checkbox
          sizeClassName="w-5 h-5"
          labelClassName="text-xs font-normal"
          name={""}
          label={"All"}
          checked={selectedSize === ""}
          onChange={(checked) => {
            setselectedSize("");
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }}
        // defaultChecked={colorsState.includes(item.name)}
        // onChange={(checked) => handleChangeColors(checked, item.name)}
        />
        <Checkbox
          sizeClassName="w-5 h-5"
          labelClassName="text-xs font-normal"
          name={"none"}
          label={"None"}
          checked={selectedSize === "none"}
          onChange={(checked) => { setselectedSize("none") }}
        // defaultChecked={colorsState.includes(item.name)}
        // onChange={(checked) => handleChangeColors(checked, item.name)}
        />
        {allSizes.map((item: any) => (
          <div key={item} className="">
            <Checkbox
              name={item}
              label={item}
              checked={selectedSize === item}
              // defaultChecked={sizesState.includes(item.name)}
              onChange={(checked) => {
                setselectedSize(item);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              }}
              // onChange={(checked) => handleChangeSizes(checked, item.name)}
              sizeClassName="w-5 h-5"
              labelClassName="text-xs font-normal"
            />
          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsPriceRage = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-5 pr-3">
        <div className="space-y-5">
          <span className="font-semibold">Price range</span>
          <Slider
            trackStyle={{ backgroundColor: "#74BC1F", }}
            railStyle={{ backgroundColor: "#c7e4a5" }}
            handleStyle={{
              borderColor: "#74BC1F",
              backgroundColor: "#74BC1F"
            }}
            range
            min={0}
            max={999}
            step={1}
            defaultValue={[0, 999]}
            allowCross={false}
            onAfterChange={(_input: any | number | number[]) => {

              // setRangePrices(_input as number[]);
              setminPrice(_input[0]);
              setmaxPrice(_input[1]);

            }

            }
            onChange={(_input: any | number | number[]) => {
              setRangePrices(_input as number[]);
              // setminPrice(_input[0]);
              // setmaxPrice(_input[1]);
            }
            }
          // onChange={(e : any)=>{
          //   console.log(">>>>>>>",e)
          //   setminPrice(e[0]);
          //   setmaxPrice(e[1]);
          // }}
          />
        </div>

        <div className="flex justify-between space-x-5">
          <div>
            <label
              htmlFor="minPrice"
              className="block text-xs font-medium text-neutral-700 dark:text-neutral-300"
            >
              Min price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-xs">
                $
              </span>
              <input
                type="text"
                name="minPrice"
                disabled
                id="minPrice"
                className="block w-32 pr-10 pl-4 sm:text-xs border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[0]}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="maxPrice"
              className="block text-xs font-medium text-neutral-700 dark:text-neutral-300"
            >
              Max price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-xs">
                $
              </span>
              <input
                type="text"
                disabled
                name="maxPrice"
                id="maxPrice"
                className="block w-32 pr-10 pl-4 sm:text-xs border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[1]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // OK
  const renderTabsSortOrder = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Sort order</h3>
        <Radio
          id={""}
          key={""}
          name="radioNameSort"
          value={""}
          label={"None"}
          checked={filterSortValue === ""}
          // defaultChecked={sortOrderStates === item.id}
          sizeClassName="w-5 h-5"
          onChange={setFilterSortValue}
          className="!text-xs"
        />
        {DATA_sortOrderRadios.map((item) => (
          <Radio
            id={item.id}
            key={item.id}
            value={item.id}
            name="radioNameSort"
            label={item.name}
            checked={filterSortValue === item.id}
            // defaultChecked={sortOrderStates === item.id}
            sizeClassName="w-5 h-5"
            onChange={setFilterSortValue}
            className="!text-xs"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="divide-y divide-slate-200 dark:divide-slate-700">
      {renderTabsCategories()}

      {allSubs.length > 0 && selectedCat !== "" && renderTabsSubCategories()}
      {renderTabsColor()}
      {renderTabsSize()}
      {renderTabsPriceRage()}
      {/* <div className="py-8 pr-2">
        <MySwitch
          label="On sale!"
          desc="Products currently on sale"
          enabled={isOnSale}
          onChange={setIsIsOnSale}
        />
      </div> */}
      {renderTabsSortOrder()}
    </div>
  );
};

export default SidebarFilters;
