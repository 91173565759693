import React from "react";
import { Helmet } from "react-helmet";
import SectionAds from "./SectionAds";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionPromo3 from "components/SectionPromo3";

// DEMO DATA

const BlogPage: React.FC = () => {
  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Blog || Oz Cleaning Supplies</title>
        <meta name="description" content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!" />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={"/blog/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/blog"
}
    `}</script>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      <div className="container relative">
        {/* === SECTION 1 === */}
        <div className="pt-12 pb-16 lg:pb-28">
          <SectionMagazine5 />
        </div>

        {/* === SECTION 1 === */}
        {/* <SectionAds /> */}

        {/* === SECTION 8 === */}
        {/* <SectionLatestPosts className="py-16 lg:py-28" /> */}
        <div className="py-16 lg:py-28"></div>

        {/* === SECTION 1 === */}
        {/* <SectionPromo3 className="pb-16 lg:pb-28" /> */}
      </div>
    </div>
  );
};

export default BlogPage;
