import AuthContext from "Context/AuthContext";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";


export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {

  const [userD, setUserD] = React.useState<any | null>("");
  const { user, setUser } = useContext(AuthContext);

  const [tempUser, settempUser] = useState<any>(null);

  React.useEffect(() => {

    let userNew: any = localStorage.getItem(`${window.location.hostname}.userDetails`)

    userNew = JSON.parse(userNew);

    // console.log("--------->", user);
    // const response = window.localStorage.getItem(
    //   `${window.location.hostname}.userDetails`
    // );
    // setUserD(JSON.parse(response));
    let obj: any = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    }

    setUserD(obj);
  }, [user]);

  React.useEffect(() => {

    if (localStorage.getItem("tempUser")) {
      settempUser(localStorage.getItem("tempUser"))
    } else {
      settempUser(null);
    }

  }, [])


  return (
    <div className="nc-CommonLayoutProps container">
      <div className="mt-14 sm:mt-20">
        <div className="max-w-4xl mx-auto">
          <div className="max-w-2xl">
            <h2 className="text-2xl xl:text-3xl font-semibold">Account</h2>
            {
              !tempUser &&

              <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
                <span className="text-slate-900 dark:text-slate-200 font-semibold">
                  {userD?.firstName + " " + userD?.lastName},
                </span>{" "}
                {/* Oz Cleaning Supplies@gmail.com · Los Angeles, CA */}
                {`${userD?.email}`}
              </span>


            }

          </div>
          <hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>

          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            {_.compact([
              !tempUser &&
              {
                name: "Account info",
                link: "/account",
              },
              {
                name: "Save lists",
                link: "/account-savelists",
              },
              {
                name: " My order",
                link: "/account-my-order",
              },
              !tempUser &&
              {
                name: "Change password",
                link: "/account-change-password",
              },
              !tempUser &&
              {
                name: "Change Billing",
                link: "/account-billing",
              },
            ]).map((item, index) => (
              <NavLink
                key={index}
                activeClassName="!border-primary-500 font-medium !text-slate-900 dark:!text-slate-200 "
                to={item.link}
                className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0 text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200 text-xs sm:text-sm"
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          <hr className="border-slate-200 dark:border-slate-700"></hr>
        </div>
      </div>
      <div className="max-w-4xl mx-auto pt-14 sm:pt-26 pb-24 lg:pb-32">
        {children}
      </div>
    </div>
  );
};

export default CommonLayout;
